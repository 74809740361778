function Error(){
    return(
        <div className="erreur404Box">
            <h2>Arfff... 404</h2>

            <div className="backBox">
                <a href="../">Retourner sur la page d'accueil</a>
            </div>
        </div>
    )
}

export default Error