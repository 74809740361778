import ContactUs from "../Components/ContactUs"

function Contact(){
  return(
    <div className="formBox">
          {ContactUs()}
      </div>
    )
  }

export default Contact